import PropTypes from "prop-types"
import React from "react"
import EDABanner from "./eda-banner"
import NavBar from "./nav-main"
import Hero from "./hero"

const Header = () => (
  <header>
    <EDABanner />
    <NavBar />
    <Hero />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
